import React, {Component} from 'react';
import { connect } from 'react-redux';
import { Status } from '../../core/api/Enums/Status';
import DatePicker from '../../core/components/Forms/DatePicker';
import Loader from '../../core/components/Loading/Loader';
import CurrencyParser from '../../core/helpers/CurrencyParser';
import { ModuleNamesList } from '../../core/lists/ModuleNamesList';
import InfoMessageService from '../../core/services/InfoMessageService';
import TranslationService from '../../core/services/TranslationService';
import { IExtendedModuleProps } from '../../core/types/IExtendedModuleProps';
import { IStore } from '../../reducers/IStore';
import { ActionButtons } from './components/ActionButtons';
import { CreditNoteMaxInfo } from './components/CreditNoteMaxInfo';
import { CreditNoteNumberInput } from './components/CreditNotePaymentAmount';
import { DirectPaymentAmount } from './components/DirectPaymentAmount';
import { DirectPaymentLargerAmountNotice } from './components/DirectPaymentLargerAmountNotice';
import './DirectPayment.scss';
import InvoicesTableConfigurator from './helpers/InvoicesTableConfigurator';
import { isPaymentDateValid, validatePayment } from './helpers/paymentValidator';
import DirectPaymentService from './services/DirectPaymentService';
import { IEditableInvoiceListElement } from './types/IEditableInvoiceListElement';
import { PaymentMethod } from './types/PaymentMethod';
import { ICurrency } from './types/ICurrency';
import ListInputs from './../../core/components/Forms/ListInputs';
import { BootstrapTableContainer } from '../../core/components/BootstrapTable/BootstrapTableContainer';

interface IState {
    amount: number,
    automaticDistribution: boolean
    creditNoteNumber: string,
    currencies: ICurrency[],
    errors: string[],
    originalInvoices: IEditableInvoiceListElement[],
    invoices: IEditableInvoiceListElement[],
    isLoading: boolean,
    maximumAmount: number,
    page: number,
    pageSize: number,
    paymentDate: Date,
    paymentMethod: PaymentMethod,
    selectedCurrency: string,
    total: number,
}

const clearedPartOfState = {
    amount: 0,
    automaticDistribution: false,
    creditNoteNumber: '',
    currencies: [],
    errors: [],
    maximumAmount: 0,
    page: 1,
    pageSize: 10,
    paymentDate: new Date(),
    total: 0,
};

interface IProps extends IExtendedModuleProps {
    currency: string
}

class DirectPayment extends Component<IProps, IState> {
    state: IState = {
        amount: 0,
        automaticDistribution: true,
        creditNoteNumber: '',
        currencies: [],
        errors: [],
        originalInvoices: [],
        invoices: [],
        isLoading: false,
        maximumAmount: 0,
        page: 1,
        pageSize: 10,
        paymentDate: new Date(),
        selectedCurrency: '',
        paymentMethod: PaymentMethod.DirectPayment,
        total: 0,
    };

    async componentDidMount() {
        this.setState({ selectedCurrency: this.props.currency });
        await this.loadInvoices();
    }

    render() {
        return (
            <article className="l-module direct-payment">
                {this.state.isLoading && <Loader opacity={1}/>}

                <section className="l-module__section">
                    <div className="row">
                        <div className="col-12 col-sm-5">
                            <ListInputs
                                className="mb"
                                label={TranslationService.translateModule('ChoseType', this.props.module.name)}
                                listInputs={[{
                                    key: PaymentMethod.DirectPayment as any,
                                    value: TranslationService.translateModule('DirectPayment', this.props.module.name)
                                }, {
                                    key: PaymentMethod.CreditNote as any,
                                    value: TranslationService.translateModule('CreditNote', this.props.module.name)
                                }]}
                                selectedInputs={[this.state.paymentMethod as any]}
                                onSelect={this.onPaymentMethodSelect}/>                        
                        </div>

                        <div className="col-12 col-sm-7 col-md-6">
                            {this.state.paymentMethod === PaymentMethod.CreditNote &&
                                <CreditNoteNumberInput onChange={this.onCreditNoteNumberChange} />
                            }

                            {this.state.paymentMethod === PaymentMethod.DirectPayment &&
                                <>
                                    <div className="c-control">
                                        <div className="row row--aligned">
                                            <label className="c-control__label col-6" htmlFor="directPaymentDate">
                                                {TranslationService.translateModule('PaymentReceivedOn', ModuleNamesList.DirectPayment)}
                                            </label>

                                            <div className="col-6">
                                                <DatePicker
                                                    inputId="directPaymentDate"
                                                    inputName="paymentDate" 
                                                    value={this.state.paymentDate} 
                                                    onChange={(date: Date) => {
                                                        this.setState({
                                                            paymentDate: date
                                                        })
                                                    }}
                                                />
                                            </div>
                                            
                                            <div className={`c-input-error ${isPaymentDateValid(this.state.invoices, this.state.paymentDate) ? 'd-none' : null}`}>
                                                {TranslationService.translateModule('PaymentDateBetweenLowestInvoiceDateAndTodayError', ModuleNamesList.DirectPayment)}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="c-control">
                                        <div className="row row--aligned">
                                            <label className="c-control__label col-6" htmlFor="time">
                                                {TranslationService.translateModule('MaximumAmountOfDirectPayment', ModuleNamesList.DirectPayment)}
                                            </label>

                                            <div className="col-6">
                                                {CurrencyParser.toLocaleString(this.state.maximumAmount)}
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <div className="row row--aligned">
                                            <label className="c-control__label col-6" htmlFor="directPaymentAmount">
                                                {TranslationService.translateModule('Amount', ModuleNamesList.DirectPayment)}
                                            </label>

                                            <div className="col-6">
                                                <DirectPaymentAmount 
                                                    amount={this.state.amount}
                                                    maximumAmount={this.state.maximumAmount} 
                                                    onChange={this.onAmountChange}
                                                    onCurrencyChange={this.onCurrencyChange}
                                                    currencies={this.state.currencies}
                                                    selectedCurrency={this.state.selectedCurrency}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="c-control">
                                        <div className="row row--aligned">
                                            <label className="c-control__label col-6" htmlFor="directPaymentAutomaticDistribution">
                                                {TranslationService.translateModule('DistributeAutomatically', ModuleNamesList.DirectPayment)}
                                            </label>

                                            <div className="col-6">
                                                <input
                                                    id="directPaymentAutomaticDistribution"
                                                    type="checkbox" 
                                                    checked={this.state.automaticDistribution} 
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => { 
                                                        this.onAutomaticDistributionChange(event.target.checked); 
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="c-control">
                                        <DirectPaymentLargerAmountNotice />
                                    </div>
                                </> 
                            }

                            <div className="offset-6 col-6">
                                {this.state.paymentMethod === PaymentMethod.CreditNote && 
                                    <CreditNoteMaxInfo amount={this.state.maximumAmount} currency={this.props.currency} />
                                }
                            </div>
                        </div>
                    </div>
                </section>

                <section className="l-module__section mt-4">
                    <BootstrapTableContainer
                        remote={false}
                        data={this.state.invoices}
                        keyField="id"
                        wrapperClasses="bt"
                        classes="bt__table bt-table"
                        paginationProps={{
                            page: this.state.page,
                            sizePerPage: this.state.pageSize,
                            totalSize: this.state.total
                        }}
                        cellEdit={{
                            mode: 'click',
                            blurToSave: true,
                            autoSelectText: true,
                            afterSaveCell: (oldValue: any, newValue: any, row: any) => {
                                const item = this.state.invoices.filter((i) => i.id === row.id);
                                const itemIdx = this.state.invoices.indexOf(item[0]);
                                const editedItem = { ...item[0], payAmount: newValue };
                                if (this.state.paymentMethod === PaymentMethod.CreditNote) {
                                    editedItem.payAmount = newValue;
                                    row.payAmount = newValue;
                                } else {
                                    editedItem.order = newValue;
                                    row.order = newValue;
                                }

                                this.setState({
                                    invoices: [
                                        ...this.state.invoices.slice(0, itemIdx),
                                        editedItem,
                                        ...this.state.invoices.slice(itemIdx + 1),
                                    ],
                                });
                            }
                        }}
                        columns={InvoicesTableConfigurator.getInvoicesListTableColumns(this.state.paymentMethod, this.state.automaticDistribution)}
                    />

                    {this.state.errors.length > 0 && (
                        <div className="row d-flex validation-errors-summary">
                            <div className="card card-body alert-danger">
                                <ul>
                                    {this.state.errors.map((err, index: number) => (
                                        <li key={index}>{TranslationService.translateModule(err, this.props.module.name)}</li>)
                                    )}
                                </ul>
                            </div>
                        </div>
                    )}

                    <div className="row">
                        <ActionButtons
                            onSave={this.onSave}
                            onCancel={this.onCancel} 
                        />
                    </div>
                </section>
            </article>
        )
    }

    private onAutomaticDistributionChange = (value: boolean) => {
        this.setState({ automaticDistribution: value });
        if (value) {
            this.resotreOriginalOrdering();
        }
    };

    private resotreOriginalOrdering = () => {

        const originalOrder = this.state.originalInvoices.map(i => ({ id: i.id, invoiceNumber: i.invoiceNumber }));
        this.setState({
            invoices: [...this.state.invoices.map(i => {
                const originalOrderItem = originalOrder.filter(ooi => ooi.id === i.id && ooi.invoiceNumber === i.invoiceNumber);
                const originalIndex = originalOrder.indexOf(originalOrderItem[0]);
                return {
                    ...i,
                    order: originalIndex + 1
                };
            })
            ]
        })
    };

    private onAmountChange = (value: number) => {
        this.setState({ amount: value });
    };

    private onCurrencyChange = (selectedCurrency: string) => {
        let maximumAmount = this.state.invoices.map(i => this.balanceInBaseCurrency(i.balance, i.currency, this.state.currencies)).reduce((sum, next) => sum + next);
        const currencyRate = this.state.currencies.find(i => i.currencyId === selectedCurrency)!.currencyRate;
        
        if (currencyRate > 0){
            maximumAmount = this.roundAmount(maximumAmount / currencyRate)
        }

        this.setState({ selectedCurrency, maximumAmount });
    }

    private roundAmount = (amount: number) => {
        return Math.round(amount * 100) / 100
    }

    private balanceInBaseCurrency = (balance: number, currency: string, currencies: ICurrency[]) => {
        const currencyRate = currencies.find(i => i.currencyId === currency)!.currencyRate;
        if (currencyRate > 0){
            return this.roundAmount(balance * currencyRate)
        }

        return balance
    }

    private onCreditNoteNumberChange = (value: string) => {
        this.setState({ creditNoteNumber: value });
    };

    private onSave = async () => {
        const errors = validatePayment(this.state.invoices, this.state.maximumAmount, this.state.amount, this.state.paymentDate, this.state.paymentMethod);
        if (!errors.length) {
            try {
                this.setState({
                    isLoading: true
                });

                const result = await DirectPaymentService.savePayment(this.props.module.id, {
                    caseId: this.props.caseId,
                    paymentMethod: this.state.paymentMethod,
                    invoices: this.state.invoices,
                    automaticDistribution: this.state.automaticDistribution,
                    paymentReceivedOn: this.state.paymentDate,
                    amount: this.state.amount,
                    creditNoteNumber: this.state.creditNoteNumber,
                    currency: this.state.selectedCurrency
                });

                InfoMessageService.displayActionStatus(result, true);

                this.setState({
                    isLoading: true
                });

                this.callExitHandler(result.status === Status.Success);
            } catch (err) {
                let errorCodes = [];
                if (err.response && err.response.data) {
                    errorCodes = Object.keys(err.response.data).map(k => err.response.data[k]).reduce((arr: any[], next: any[]) => [...arr, ...next]);
                }

                InfoMessageService.error(
                    TranslationService.translateModule(
                        this.state.paymentMethod === PaymentMethod.DirectPayment
                            ? 'ErrorSavingDirectPayment'
                            : 'ErrorSavingCreditNote',
                        this.props.module.name
                    )
                );

                this.setState({
                    isLoading: false,
                    errors: errorCodes
                });
            }
        } else {
            this.setState({
                errors
            });
        }
    };

    private onCancel = () => {
        this.callExitHandler();
    };

    private callExitHandler = (actionStatus?: boolean) => {
        if (this.props.exit) {
            this.props.exit(actionStatus);
        }
    };

    private onPaymentMethodSelect = (item: any) => {
        if (item.key !== this.state.paymentMethod) {
            this.setState({
                ...clearedPartOfState,
                paymentMethod: parseFloat(item.key) as PaymentMethod,
            }, this.loadInvoices);
        }
    };

    private loadInvoices = async () => {
        this.setState({
            isLoading: true
        });

        try {
            const result = await DirectPaymentService.getCaseInvoices(this.props.caseId, this.props.module.id);

            const invoices = result.invoices;
            const maximumAmount = invoices.map(i => this.balanceInBaseCurrency(i.balance, i.currency, result.currencies)).reduce((sum, next) => sum + next);
            this.setState({
                currencies: result.currencies,
                isLoading: false,
                invoices: invoices.map((i, index) => ({ ...i, payAmount: 0, order: index + 1 })),
                originalInvoices: invoices.map((i, index) => ({ ...i, payAmount: 0, order: index + 1 })),
                maximumAmount,
                amount: maximumAmount,
                total: invoices.length
            })
        } catch {
            InfoMessageService.error(TranslationService.translateModule('ErrorLoadingInvoices', this.props.module.name));

            this.setState({
                isLoading: false
            })
        }

    }
}

const mapStateToProps = (state: IStore) => ({
   currency: state.currency 
});

export default connect(mapStateToProps)(DirectPayment);