import * as React from 'react';
import * as Reactstrap from 'reactstrap';
import TranslationService from '../../services/TranslationService';
import Loader from '../Loading/Loader';

interface IProps {
    isOpen: boolean;
    message?: string;
    isModalContentLoading: boolean;
    onCancel(): void;
    onOk(): void;
}

export default class ConfirmationModal extends React.Component<IProps> {
    static defaultProps = {
        isModalContentLoading: false,
    }

    public render() {
        return (
            <Reactstrap.Modal isOpen={this.props.isOpen} className="c-modal modal-md" centered={true}>
                <Reactstrap.ModalHeader className="c-modal__header modal-header">
                    {TranslationService.translate('ConfirmModalDefaultHeader')}
                </Reactstrap.ModalHeader>

                <Reactstrap.ModalBody className="c-modal__body">
                    {this.props.isModalContentLoading ? (
                        <Loader opacity={0.6}/>
                    ) : (
                        this.props.message ? this.props.message : TranslationService.translate('ConfirmModalDefaultContent')
                    )}
                </Reactstrap.ModalBody>
                
                <Reactstrap.ModalFooter>
                    <button onClick={() => this.props.onCancel()} className="btn btn--normal btn-color-3 btn-sm mr-1">{TranslationService.translate('ConfirmModalCancelButton')}</button>
                    <button onClick={() => this.props.onOk()} className="btn btn--normal btn-color-2 btn-sm">{TranslationService.translate('ConfirmModalConfirmButton')}</button>
                </Reactstrap.ModalFooter>
            </Reactstrap.Modal>
        )
    }
}