import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import NoPermissionsComponent from '../../core/components/NoPermissionsComponent/NoPermissionsComponent';
import RouteParamsService from '../../core/services/RouteParamsService';
import TranslationService from '../../core/services/TranslationService';
import { IModuleProps } from '../../core/types/IModuleProps';
import { IStore } from '../../reducers/IStore';
import './CaseSummary.scss';
import CaseSummaryService from './services/CaseSummaryService';
import { CaseSummary as CaseSummaryType, emptyCaseSummary } from './types/CaseSummary';
import { flatModuleOptions } from '../../core/api/helpers/flatModuleOptions';
import { getModalOpenEvent } from '../../core/helpers/Modal/ModalEvents';
import { ModuleNamesList } from '../../core/lists/ModuleNamesList';
import ModuleEventSubscriber from '../../core/helpers/ModuleEventSubscriber';
import { EventsList } from '../../core/lists/EventsList';
import { CaseSummaryInfo } from './CaseSummaryInfo';
import { CaseSummaryPayments } from './CaseSummaryPayments';
import { CaseSummaryActions } from './CaseSummaryActions';
import Loader from '../../core/components/Loading/Loader';
import InfoMessageService from '../../core/services/InfoMessageService';
import { CaseSummaryModule } from './types/CaseSummaryModule';

interface IState {
    caseSummary: CaseSummaryType,
    hasAccessToModule: boolean,
    isDataLoaded: boolean,
}

const emptyState: IState = {
    caseSummary: { ...emptyCaseSummary },
    hasAccessToModule: false,
    isDataLoaded: false
};

interface IProps extends IModuleProps {
    caseId: string,
    locale: string,
    currency: string,
}

class CaseSummary extends Component<IProps, IState> {
    state: IState = {
        ...emptyState
    };

    module: CaseSummaryModule = flatModuleOptions<CaseSummaryModule>(this.props.module as CaseSummaryModule);

    async componentDidMount() {
        await this.fetchData();
    }

    render() {
        if (!this.hasRequiredUrlParams()) {
            return <Redirect to="/error" />
        }

        if (!this.state.hasAccessToModule && this.state.isDataLoaded) {
            return <NoPermissionsComponent />
        }

        return (
            <article className="l-module l-module--full-height">
                <section className={`l-module__section ${!this.state.isDataLoaded ? 'l-module__section--loading' : ''} mt-3`}>
                    {!this.state.isDataLoaded &&
                        <Loader opacity={0.5} />
                    }

                    <div className="case-summary">
                        <CaseSummaryInfo module={this.props.module as CaseSummaryModule} caseSummary={this.state.caseSummary} />

                        <CaseSummaryPayments
                            moduleName={this.props.module.name}
                            caseSummary={this.state.caseSummary}
                            currency={this.props.currency}
                        />

                        <CaseSummaryActions
                            moduleName={this.props.module.name}
                            options={{
                                showDirectPaymentButton: Number(this.module.DirectPaymentModalModuleInstanceId) > 0 && this.state.caseSummary.closeDate === null,
                                showWithdrawCaseButton: Number(this.module.WithdrawCaseModalModuleInstanceId) > 0,
                                showPrintCaseButton: Number(this.module.PrintCaseModalModuleInstance) > 0,
                                showUserCaseNote: this.module.ShowUserCaseNotesButton === 'true',
                                showUploadFileButton: this.module.ShowUploadFileButton === 'true'
                            }}
                            openDirectPaymentModal={this.openDirectPaymentModal}
                            openWithdrawCaseModal={this.openWithdrawCaseModal}
                            openPrintCaseModal={this.openPrintCaseModal}
                            uploadFile={this.uploadFile}
                            openUserCaseNoteModal={this.handleOpenUserCaseNoteModal}
                        />
                    </div>
                </section>
            </article>
        );
    }

    fetchData = async () => {
        try {
            const caseSummary = await CaseSummaryService.getCaseSummary(this.props.caseId);
            this.setState({
                caseSummary,
                hasAccessToModule: true,
                isDataLoaded: true
            })
        } catch (err) {
            if (err.response && err.response.status === 403) {
                this.setState({
                    hasAccessToModule: false,
                    isDataLoaded: true
                })
            }
        }
    };

    hasRequiredUrlParams = () => {
        return this.props.caseId;
    };

    updateCaseData = () => {
        this.fetchData();
        ModuleEventSubscriber.emitEvent({
            name: EventsList.CASE_DATA_UPDATED
        })
    };

    uploadFile = async (file: File) => {
        try {
            await CaseSummaryService.uploadCaseFile(this.props.caseId, this.module.id.toString(), file);
            InfoMessageService.success(TranslationService.translateModule('UploadFileSuccess', this.module.name));
            ModuleEventSubscriber.emitEvent({
                name: EventsList.CASE_DATA_UPDATED
            })
        } catch {
            InfoMessageService.error(TranslationService.translateModule('UploadFileError', this.module.name));
        }
    }

    openDirectPaymentModal = () => {
        ModuleEventSubscriber.emitEvent({
            name: getModalOpenEvent(this.module.DirectPaymentModalModuleInstanceId), data: {
                modalData: {
                    MODAL_HEADER_TEXT: TranslationService.translateModule('DirectPaymentModalTitle', ModuleNamesList.DirectPayment) + ' ' + this.props.caseId,
                    MODAL_HEADER_ICON: 'fas fa-credit-card',
                    onClose: (actionSuccess?: boolean) => { if (actionSuccess) { this.updateCaseData(); } }
                },
                otherData: {
                    caseId: this.props.caseId
                }
            }
        });
    };

    openWithdrawCaseModal = () => {
        ModuleEventSubscriber.emitEvent({
            name: getModalOpenEvent(this.module.WithdrawCaseModalModuleInstanceId), data: {
                modalData: {
                    MODAL_HEADER_TEXT: TranslationService.translateModule('WithdrawCaseMessageTitle', ModuleNamesList.WithdrawCase) + ' ' + this.props.caseId,
                    MODAL_HEADER_ICON: 'fas fa-ban',
                    onClose: (actionSuccess?: boolean) => { if (actionSuccess) { this.updateCaseData(); } }
                },
                otherData: {
                    caseId: this.props.caseId
                }
            }
        });
    }

    openPrintCaseModal = () => {
        ModuleEventSubscriber.emitEvent({
            name: getModalOpenEvent(this.module.PrintCaseModalModuleInstance), data: {
                modalData: {
                    MODAL_HEADER_TEXT: TranslationService.translateModule('PrintCaseModalTitle', ModuleNamesList.PrintCase) + ' ' + this.props.caseId,
                    MODAL_HEADER_ICON: 'fas fa-print',
                    onClose: (actionSuccess?: boolean) => { if (actionSuccess) { this.updateCaseData(); } }
                },
                otherData: {
                    caseId: this.props.caseId,
                    hideMessagesOnPrint: this.module.HideMessagesOnPrint === 'true',
                    hideNotesOnPrint: this.module.HideNotesOnPrint === 'true'
                }
            }
        });
    }

    handleOpenUserCaseNoteModal = () => {
        ModuleEventSubscriber.emitEvent({
            name: getModalOpenEvent(this.module.UserCaseNotesModalModuleInstance), data: {
                modalData: {
                    MODAL_HEADER_TEXT: TranslationService.translateModule('UserCaseNotesModalTitle', ModuleNamesList.UserCaseNotes) + ' ' + this.props.caseId,
                    MODAL_HEADER_ICON: 'far fa-sticky-note',
                    className: 'user-case-note-modal',
                }
            }
        });
    }
}

const mapStateToProps = (state: IStore) => {
    const queryparams = RouteParamsService.getQueryParameters();
    return {
        caseId: queryparams.id,
        currency: state.currency,
        locale: state.currentCultureCode,
    }
};

export default connect(mapStateToProps)(CaseSummary);